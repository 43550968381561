// Import from NPM
// -------------------------------------
import React from "react";
import { useFeatures } from "flagged";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Div, Icon } from "@components/Generics.react";
import "@styles/courseLayout.scss";
import { text_truncate } from "@utilities/helpers";

export default function AppName(props) {
    const { isolated, rootPage, closeDeck, user, appName } = props;
    const features = useFeatures();
    const navigate = useNavigate();
    const isPortrait = window.innerWidth < window.innerHeight;
    const { t } = useTranslation("common");

    // ========================= Render Function =================================
    return (
        <Div padded={!isPortrait} fullht className="pagename">
            {isolated && !isPortrait ? (
                <Div padTop altText uppercase>
                    {appName
                        ? appName
                        : features.tenanting.multiTenanted
                        ? user.branding?.name
                        : t("appName")}
                </Div>
            ) : !isPortrait ? (
                <Div altText>
                    {appName
                        ? appName
                        : features.tenanting.multiTenanted
                        ? user.branding?.name
                        : t("appName")}
                </Div>
            ) : (
                <Div inline altText>
                    {text_truncate(appName
                        ? appName
                        : features.tenanting.multiTenanted
                        ? user.branding?.name
                        : t("appName"),25)}
                </Div>
            )}
            {!isPortrait && !rootPage && (
                <Div
                    fit-content
                    padded
                    ivory
                    snubbed
                    small
                    clickable
                    hoverFade
                    slightShadow
                    nudgeRight
                    txtHalf
                    onClick={() => (closeDeck ? closeDeck() : navigate(-1))}
                    style={{ marginTop: "5px" }}
                >
                    <Icon name="chevron left" />
                </Div>
            )}
        </Div>
    );
}
